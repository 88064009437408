import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '~/lib/utils';

const badgeVariants = cva(
  '',
  {
    variants: {
      variant: {
        body: '',
        h1: 'font-medium text-6xl leading-tight',
        h2: 'text-2xl font-medium',
        h3: 'text-lg font-medium leading-tight',
        h4: '',
        h5: '',
        h6: '',
        subtitle1: 'text-xl',
        subtitle2: '',
        caption: 'text-xs',
        overline: 'text-xs uppercase'
      }
    },
    defaultVariants: {
      variant: 'body'
    }
  }
);

export interface BadgeProps extends React.HTMLAttributes<HTMLElement>, VariantProps<typeof badgeVariants> {}

function Typography({ className, variant, ...props }: BadgeProps) {
  switch (variant) {
    case 'body':
      return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
    case 'h1':
      return <h1 className={cn(badgeVariants({ variant }), className)} {...props} />;
    case 'h2':
      return <h2 className={cn(badgeVariants({ variant }), className)} {...props} />;
    case 'h3':
      return <h3 className={cn(badgeVariants({ variant }), className)} {...props} />;
    case 'h4':
      return <h4 className={cn(badgeVariants({ variant }), className)} {...props} />;
    case 'h5':
      return <h5 className={cn(badgeVariants({ variant }), className)} {...props} />;
    case 'h6':
      return <h6 className={cn(badgeVariants({ variant }), className)} {...props} />;
    case 'subtitle1':
      return <h6 className={cn(badgeVariants({ variant }), className)} {...props} />;
    case 'subtitle2':
      return <h6 className={cn(badgeVariants({ variant }), className)} {...props} />;
    case 'caption':
      return <span className={cn(badgeVariants({ variant }), className)} {...props} />;
    case 'overline':
      return <span className={cn(badgeVariants({ variant }), className)} {...props} />;
    default:
      return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
  }
}

export { Typography, badgeVariants };
