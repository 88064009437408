import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { RouterOutputs } from '~/trpc/shared';

const cartsAtom = atomWithStorage<string[]>('cartIds', []);
const cartOpenAtom = atom<boolean>(false);
const addToCartDateSelectionAtom = atom<{
  isOpen: boolean;
  product: RouterOutputs['product']['search'][number] | null;
}>({
  isOpen: false,
  product: null
});

export { cartsAtom, cartOpenAtom, addToCartDateSelectionAtom };
