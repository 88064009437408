'use client';

import { useTranslations } from 'next-intl';
import { useAtom } from 'jotai';
import { api } from '~/trpc/react';
import { RouterOutputs } from '~/trpc/shared';

import Image from 'next/image';
import { cartsAtom, cartOpenAtom } from '~/atoms';

import { Card } from '~/components/ui/card';
import { Button } from '~/components/ui/button';
import { Typography } from '~/components/ui/typography';
import { Trash } from 'lucide-react';
import { toast } from 'sonner';

import { fCurrency } from '~/utils/formatNumber';
import { Skeleton } from '~/components/ui/skeleton';

export default function CartBlock(props: { cart: RouterOutputs['cart']['get'][number] }) {
  const utils = api.useContext();
  const [cartIds, setCartIds] = useAtom(cartsAtom);
  const t = useTranslations();
  const { data: lineItems, isLoading } = api.cart.getLineItems.useQuery({ cartId: props.cart.cart.id });
  const removeFromCart = api.cart.removeFromCart.useMutation();
  const deleteCart = api.cart.delete.useMutation();

  const handleRemoveFromCart = async (productId: string) => {
    const isLastItemInCart = lineItems?.length === 1;

    try {
      await removeFromCart.mutateAsync({
        cartId: props.cart.cart.id,
        productId: productId
      });

      utils.cart.get.invalidate();
      utils.cart.getLineItems.invalidate();

      toast.success(t('removedFromBooking'));

      if (!isLastItemInCart) return;

      await deleteCart.mutateAsync({
        cartIds: [props.cart.cart.id]
      });

      const newCarts = cartIds.filter((cartId) => cartId !== props.cart.cart.id);
      setCartIds(newCarts);
    } catch (err) {
      console.error(err);
      toast.error(t('Error removing item from cart. Please contact support.'));
    }
  };

  if (lineItems?.length === 0) return null;

  return (
    <Card className="pb-3">
      <div className="flex flex-row justify-between items-center px-4 mt-4 mb-3">
        <div className="flex flex-row gap-2">
          <div className="h-[30px] w-[30px] relative rounded-full shadow-lg overflow-hidden">
            <Image
              src={props.cart.merchant?.thumbnail || ''}
              alt={props.cart.merchant?.name || ''}
              fill
              objectFit="cover"
              sizes="(max-width: 50px) 100vw, 33vw"
            />
          </div>
          <div className="flex flex-col gap-0">
            <Typography variant="body" className="text-sm font-medium">
              {props.cart.merchant?.name}
            </Typography>

            <Typography variant="body" className="text-xs text-gray-500">
              {props.cart.cart.rentalStartDate.toLocaleDateString('et-EE')} -{' '}
              {props.cart.cart.rentalEndDate.toLocaleDateString('et-EE')}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <Typography variant="body" className="text-sm">
            {fCurrency(props.cart.cart.total)}€
          </Typography>
          <Typography variant="body" className="text-xs text-gray-500">
            {t('total')}
          </Typography>
        </div>
      </div>
      <div className="shrink-0 bg-border h-[1px] w-full mb-3" />
      {isLoading ? (
        <div className="px-4 flex flex-col gap-2">
          <Skeleton className="h-8 w-full" />
          <Skeleton className="h-8 w-full" />
          <Skeleton className="h-8 w-full" />
        </div>
      ) : (
        <div className="flex flex-col gap-4 px-4">
          {lineItems?.map((lineItem) => (
            <div key={lineItem.line_item.id} className="flex flex-row gap-3 items-center w-full">
              <div className="w-[30px] h--[30px]">
                <div className="relative w-[30px] h-[30px] min-w-[30px] min-h-[30px] rounded-lg overflow-hidden aspect-square">
                  <Image src={lineItem.product?.thumbnail || ''} alt={lineItem.product?.name || ''} fill />
                </div>
              </div>
              <div className="flex flex-col flex-auto">
                <Typography variant="body" className="text-xs font-medium">
                  {lineItem.product?.name}
                </Typography>
                <div className="flex flex-row gap-2">
                  {lineItem.line_item.subTotal > lineItem.line_item.total && (
                    <Typography variant="body" className="text-xs text-muted-foreground line-through">
                      {fCurrency(lineItem.line_item.subTotal)}€
                    </Typography>
                  )}
                  <Typography variant="body" className="text-xs text-muted-foreground">
                    {fCurrency(lineItem.line_item.total)}€
                  </Typography>
                </div>
              </div>
              <Button
                variant="ghost"
                size="icon"
                className="min-w-[40px] min-h-[40px]"
                onClick={() => {
                  handleRemoveFromCart(lineItem.product?.id || '');
                }}
              >
                <Trash size={14} className="text-muted-foreground" />
              </Button>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}
